<template>
  <v-app v-if="!!invoice" app>
    <div class="pa-0 ma-0">
      <div class="header">
        <div
          class="d-flex justify-content-between align-items-center w-100 pt-6 px-12"
        >
          <div class="font-weight-bold text-h6 col-8 text-left">
            <div class="text-h2 font-weight-bolder text-uppercase">Invoice</div>
          </div>
          <div
            class="text-uppercase invoice-main font-weight-bolder col-4 text-right"
          >
            <div class="py-4">
              <img :src="invoice.logo" />
            </div>
            <div class="data-value">
              {{ invoice.organization.address.address }}
            </div>
            <div class="data-value">
              {{ invoice.organization.address.city }}
            </div>
            <div class="data-value">
              {{ invoice.organization.address.country }}
            </div>
          </div>
        </div>
      </div>
      <table style="width: 100%">
        <thead style="background: green !important">
          <tr>
            <td>
              <div class="header-space">
                <div class="pheader">
                  <div
                    class="d-flex justify-content-between align-items-center w-100 pt-6 px-12"
                  >
                    <div class="font-weight-bold text-h6 col-8 text-left">
                      <div class="text-h2 font-weight-bolder text-uppercase">
                        Invoice
                      </div>
                    </div>
                    <div
                      class="text-uppercase invoice-main font-weight-bolder col-4 text-right"
                    >
                      <div class="py-4">
                        <img :src="invoice.logo" />
                      </div>
                      <div class="data-value">
                        {{ invoice.organization.address.address }}
                      </div>
                      <div class="data-value">
                        {{ invoice.organization.address.city }}
                      </div>
                      <div class="data-value">
                        {{ invoice.organization.address.country }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="content">
                <div class="pbody">
                  <div
                    class="d-flex justify-start align-items-start flex-wrap pb-6 px-6"
                  >
                    <div class="flex-column text-center px-2 col-3">
                      <div class="font-weight-bold text-h6 text-uppercase">
                        Invoice Number
                      </div>
                      <div class="data-value">{{ invoice.invoice_number }}</div>
                    </div>
                    <div class="flex-column text-center px-2 col-3">
                      <div class="font-weight-bold text-h6 text-uppercase">
                        Currency
                      </div>
                      <div class="data-value">{{ invoice.currency }}</div>
                    </div>
                    <div class="flex-column text-center px-2 col-3">
                      <div class="font-weight-bold text-h6 text-uppercase">
                        Reference
                      </div>
                      <div class="data-value">{{ invoice.reference }}</div>
                    </div>
                    <div class="flex-column text-center px-2 col-3">
                      <div class="font-weight-bold text-h6 text-uppercase">
                        Confirmed at
                      </div>
                      <div class="data-value">{{ invoice.confirmed_at }}</div>
                    </div>
                    <div class="flex-column text-center px-2 col-3">
                      <div class="font-weight-bold text-h6 text-uppercase">
                        Date from
                      </div>
                      <div class="data-value">{{ invoice.date_from }}</div>
                    </div>
                    <div class="flex-column text-center px-2 col-3">
                      <div class="font-weight-bold text-h6 text-uppercase">
                        Date to
                      </div>
                      <div class="data-value">{{ invoice.date_to }}</div>
                    </div>
                    <div class="flex-column text-center px-2 col-3">
                      <div class="font-weight-bold text-h6 text-uppercase">
                        Payable date
                      </div>
                      <div class="data-value">{{ invoice.payable_date }}</div>
                    </div>
                  </div>
                  <div class="">
                    <v-data-table
                      :headers="headers"
                      :items="invoice.services"
                      hide-default-footer
                      item-class="text-h5"
                    >
                    </v-data-table>
                  </div>

                  <div class="py-6 px-4 text-center">
                    <div class="text-left">{{ invoice.description }}</div>
                  </div>

                  <div
                    class="d-flex justify-content-between align-items-start flex-wrap py-6"
                  >
                    <div class="flex-column text-center">
                      <div class="font-weight-bold text-h5 text-uppercase">
                        Currency
                      </div>
                      <div class="data-value">{{ invoice.currency }}</div>
                    </div>
                    <div class="flex-column text-center">
                      <div class="font-weight-bold text-h5 text-uppercase">
                        Total subtotal
                      </div>
                      <div class="data-value">{{ invoice.total_subtotal }}</div>
                    </div>
                    <div class="flex-column text-center">
                      <div class="font-weight-bold text-h5 text-uppercase">
                        Total tax
                      </div>
                      <div class="data-value">{{ invoice.total_tax }}</div>
                    </div>
                    <div class="flex-column text-center">
                      <div class="font-weight-bold text-h5 text-uppercase">
                        Total
                      </div>
                      <div
                        class="data-value text-danger font-weight-bolder text-h5"
                      >
                        {{ invoice.total }}
                      </div>
                    </div>
                  </div>
                  <div class="text-h5 pb-12">
                    {{ invoice.totalInSentence }}
                  </div>
                  <div class="">
                    <div class="text-h4 font-weight-bold">
                      Bank account detail
                    </div>
                    <div class="text-h5">
                      <div
                        class="d-flex justify-content-start align-items-center"
                      >
                        <div>Account name:</div>
                        <div class="data-value">
                          {{ invoice.organization.bank_detail.account_name }}
                        </div>
                      </div>
                      <div
                        class="d-flex justify-content-start align-items-center"
                      >
                        <div>Account number:</div>
                        <div class="data-value">
                          {{ invoice.organization.bank_detail.account_number }}
                        </div>
                      </div>
                      <div
                        class="d-flex justify-content-start align-items-center"
                      >
                        <div>Iban:</div>
                        <div class="data-value">
                          {{ invoice.organization.bank_detail.iban }}
                        </div>
                      </div>
                      <div
                        class="d-flex justify-content-start align-items-center"
                      >
                        <div>Bank:</div>
                        <div class="data-value">
                          {{ invoice.organization.bank_detail.bank }}
                        </div>
                      </div>
                      <div
                        class="d-flex justify-content-start align-items-center"
                      >
                        <div>Currency:</div>
                        <div class="data-value">
                          {{ invoice.organization.bank_detail.currency }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div class="footer-space">
                <div class="pfooter">
                  <div class="d-flex justify-space-around align-center pt-1">
                    <div>
                      <a href="mailto:info@iqfulfillment.com"
                        >info@iqfulfillment.com</a
                      >
                    </div>
                    <div class="text-h6 font-weight-bold">
                      IQ Fulfillment General Warehousing LLC
                    </div>
                    <div>
                      <a href="iqfulfillment.com">www.iqfulfillment.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <div class="footer">
        <div class="d-flex justify-space-around align-center pt-1">
          <div>
            <a href="mailto:info@iqfulfillment.com">info@iqfulfillment.com</a>
          </div>
          <div class="text-h6 font-weight-bold">
            IQ Fulfillment General Warehousing LLC
          </div>
          <div>
            <a href="iqfulfillment.com">www.iqfulfillment.com</a>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
/**
 * Invoice Template
 */

import { getToken } from "@/core/services/jwt.service";
import axios from "axios";
export default {
  name: "InvoiceTemp",
  data() {
    return {
      headers: [
        { text: "id", value: "service_id", class: "text-h5 text-uppercase" },
        {
          text: "Service Name",
          value: "service_name",
          class: "text-h6 text-uppercase",
        },
        {
          text: "Subtotal",
          value: "subtotal",
          class: "text-h6 text-uppercase",
        },
        {
          text: "Tax Total",
          value: "tax_total",
          class: "text-h6 text-uppercase",
        },
        {
          text: "Discount",
          value: "discount",
          class: "text-h6 text-uppercase",
        },
        { text: "Total", value: "total", class: "text-h5 text-uppercase" },
      ],
      invoice_data: null,
    };
  },

  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      // console.log(this.$route);
      if (this.$route.query.id) {
        const data = { id: this.$route.query.id };

        let config = {
          method: "post",
          url: `${process.env.VUE_APP_BASE_URL}/cod/invoices/show`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          data: data,
        };
        axios(config)
          .then((response) => {
            this.invoice_data = response.data.invoice;
            setTimeout(() => {
              this.$nextTick(() => {
                window.print();
              });
            }, 3000);
          })
          .catch(() => {});
      }
    },
  },
  computed: {
    invoice() {
      /**
       * store invoice data
       * @type {{
    "logo": string,
    "currency": string,
    "id": integer,
    "invoice_number": string,
    "reference": string,
    "discount": string,
    "payable_date": string,
    "date_from": string,
    "date_to": string,
    "description": string,
    "confirmed_at": string,
    "total_subtotal": string,
    "total_tax": string,
    "total": string,
    "totalInSentence": string,
    "organization": {
        "name": string,
        "trn": string,
        "address": {
            "address": string,
            "city": string,
            "country": string
        },
        "bank_detail": {
            "account_name": string,
            "account_number": string,
            "iban": string,
            "bank": string,
            "currency": string
        }
    },
    "client": {
        "name": string,
        "system_code": string,
        "trn": string
    },
    "services": [
        {
            "service_id":integer,
            "service_name": string,
            "subtotal": string,
            "tax_total": string,
            "discount": string,
            "total": string
        }
    ]
}}
       */
      let invo = null;
      invo = this.invoice_data;
      return invo;
    },
  },
};
</script>

<style>
@media print {
  .pheader {
    display: block !important;
  }
  .header {
    display: none !important;
  }
  .pfooter {
    display: block !important;
  }
  .footer {
    display: none !important;
  }

  .pheader,
  .header,
  .header-space {
    height: 220px;
  }
  .pfooter,
  .footer,
  .footer-space {
    height: 30px;
  }
}

.pbody {
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
  /* width: 100%; */
  padding: 0 20px 0 20px;
}

.header {
  height: 220px;
  width: 100%;
  background: lightgrey;
}

.pheader {
  display: none;
  position: fixed;
  top: 0px;
  width: 100%;
  /* color: rgb(255, 255, 255); */
  /* background: rgb(231, 199, 231); */
  background: lightgrey;
  /* background: rgba(128, 128, 128, 0.7); */
  /* padding: 8px; */
  z-index: 99;
}

.footer {
  width: 100%;
  background: lightgrey;
}

.pfooter {
  display: none;
  position: fixed;
  bottom: 0px;
  width: 100%;
  /* background: white !important; */
  /* color: rgb(255, 255, 255); */
  /* background: rgb(202, 164, 202); */
  background: lightgrey;
  /* padding: 8px; */
}

.data-value {
  font-weight: normal;
  color: rgb(68, 68, 68);
  font-size: medium;
}

@page {
  size: "a4";
}
/* @media print {
  .pageBreak {
    page-break-before: always;

    page-break-inside: avoid;
  }
} */
</style>
